<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0"
      ><v-row justify="center" class="mx-0">
        <v-col cols="12">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Map</span><v-spacer />
              <div style="float: right; width: 440px">
                <div style="float: left">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="allowed_operations == 'WRITE'"
                        fab
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                        @click="update_default_site_location"
                        x-small
                        :disabled="!default_coords"
                        :color="default_coords ? 'primary' : '#e0e0e0'"
                        elevation="0"
                      >
                        <v-icon>mdi-map-marker-star</v-icon></v-btn
                      >
                      <div
                        v-bind="attrs"
                        v-on="on"
                        v-else
                        style="display: inline"
                      >
                        <v-btn
                          disabled
                          fab
                          class="mr-2"
                          @click="update_default_site_location"
                          x-small
                          :color="default_coords ? 'primary' : '#e0e0e0'"
                          elevation="0"
                        >
                          <v-icon>mdi-map-marker-star</v-icon></v-btn
                        >
                      </div>
                    </template>
                    <span v-if="allowed_operations == 'WRITE'"
                      >Update Default Site Location</span
                    >
                    <span v-else
                      >You don't have permission to edit Site location</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                        @click="select_all_zones"
                        x-small
                        :color="
                          zone_selection.length == zones.length - 1
                            ? 'primary'
                            : '#e0e0e0'
                        "
                        elevation="0"
                      >
                        <v-icon>mdi-ballot-outline</v-icon></v-btn
                      >
                    </template>
                    <span>Select All Zones</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                        @click="zone_names = !zone_names"
                        x-small
                        :color="zone_names ? 'primary' : '#e0e0e0'"
                        elevation="0"
                      >
                        <v-icon>mdi-flag-variant</v-icon></v-btn
                      >
                    </template>
                    <span>Display Zone Names</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                        @click="zone_perimeter = !zone_perimeter"
                        x-small
                        :color="zone_perimeter ? 'primary' : '#e0e0e0'"
                        elevation="0"
                      >
                        <v-icon>mdi-selection</v-icon></v-btn
                      >
                    </template>
                    <span>Display Zone Perimeter</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        v-bind="attrs"
                        v-on="on"
                        @click="zone_area = !zone_area"
                        x-small
                        :color="zone_area ? 'primary' : '#e0e0e0'"
                        elevation="0"
                      >
                        <v-icon>mdi-texture-box</v-icon></v-btn
                      >
                    </template>
                    <span>Display Zone Area</span>
                  </v-tooltip>
                </div>
                <div style="width: 240px; float: right">
                  <v-select
                    hide-details
                    dense
                    class="assign_select font_400"
                    outlined
                    rounded
                    height="32"
                    style="margin-top: 2px"
                    full-width
                    v-model="selected_site"
                    :items="sites"
                    label="Site"
                  >
                  </v-select>
                </div></div
            ></v-card-title>
            <v-card-text class="pa-3">
              <v-row class="mb-3">
                <v-col cols="12" class="pb-0">
                  <div style="float: left; width: 100%">
                    <v-hover
                      v-slot="{ hover }"
                      v-for="(zone, index) in zones"
                      :key="index"
                    >
                      <v-chip
                        :class="
                          (zone_selection.includes(index)
                            ? 'selected_chip_' + zone.color
                            : '') + ' mr-2 px-0'
                        "
                        active
                        :disabled="
                          !(
                            allowed_operations == 'WRITE'
                          )
                        "
                      >
                        <span
                          style="padding: 4px 12px 4px 12px; cursor: pointer"
                          @click="
                            index != zones.length - 1
                              ? zone_selection.indexOf(index) == -1
                                ? zone_selection.push(index)
                                : zone_selection.splice(
                                    zone_selection.indexOf(index),
                                    1
                                  )
                              : ((created_zone_object = {}),
                                (zone_dialog = true))
                          "
                          >{{ zone.name }}</span
                        >
                        <v-icon
                          small
                          class="pr-3"
                          v-if="hover && index != zones.length - 1"
                          @click="select_zone_to_edit(index)"
                        >
                          mdi-pencil
                        </v-icon>
                      </v-chip>
                    </v-hover>
                  </div>
                </v-col>
              </v-row>
              <v-card>
                <v-card-text class="pa-0"
                  ><v-row v-if="cameras" class="pl-3">
                    <v-list
                      elevation="0"
                      class="map_side_bar pa-0"
                      max-width="254"
                      height="calc(75vh + 8px)"
                    >
                      <v-list-group v-if="cameras.length" value="true">
                        <template v-slot:activator>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-cctv</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="group_title"
                            >Cameras</v-list-item-title
                          >
                        </template>

                        <v-list-item v-for="(camera, i) in cameras" :key="i">
                          <v-list-item-icon>
                            <v-menu
                              offset-y
                              v-if="
                                !(
                                  camera.coordinates_lat == null &&
                                  camera.coordinates_long == null
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="get_color(camera.map_icon_color)"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="select_color(i, 'camera')"
                                  >mdi-cctv</v-icon
                                ></template
                              >
                              <v-card
                                ><v-color-picker
                                  show-swatches
                                  :swatches="swatches"
                                  class="map_icon_color_picker"
                                  v-model="selected_color"
                                >
                                </v-color-picker>

                                <v-btn
                                  class="mb-3 ml-3 mt-n2"
                                  depressed
                                  @click="remove_map_marker(i, 'camera')"
                                  ><v-icon color="primary"
                                    >mdi-map-marker-remove</v-icon
                                  ></v-btn
                                >
                              </v-card>
                            </v-menu>
                            <v-icon
                              color="gray"
                              class="non_placed_hardware"
                              v-if="
                                camera.coordinates_lat == null &&
                                camera.coordinates_long == null
                              "
                              >mdi-cctv</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            :draggable="
                              camera.coordinates_lat == null &&
                              camera.coordinates_long == null
                            "
                            :class="
                              'map_item_content_' +
                              String(
                                camera.coordinates_lat == null &&
                                  camera.coordinates_long == null
                              )
                            "
                            @dragend="select_hardware(camera, 'camera')"
                          >
                            <v-list-item-title
                              v-if="
                                !(
                                  camera.coordinates_lat == null ||
                                  camera.coordinates_lat == '' ||
                                  camera.coordinates_lat == 'null'
                                )
                              "
                              @mouseenter="set_marker(camera.name)"
                              @mouseleave="set_marker(false)"
                              class="map_sidebar_item_name"
                              >{{ camera.name }}</v-list-item-title
                            >
                            <v-list-item-title
                              v-else
                              class="map_sidebar_item_name"
                              >{{ camera.name }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>

                      <v-list-group v-if="wifis && wifis.length">
                        <template v-slot:activator>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-wifi</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title class="group_title"
                            >Wifi</v-list-item-title
                          >
                        </template>

                        <v-list-item v-for="(wifi, i) in wifis" :key="i">
                          <v-list-item-icon>
                            <v-menu
                              offset-y
                              v-if="
                                !(
                                  !this.wifis[i].coordinates_lat ||
                                  !this.wifis[i].coordinates_long
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="get_color(wifi.map_icon_color)"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="select_color(i, 'wifi')"
                                  >mdi-wifi</v-icon
                                ></template
                              >
                              <v-card
                                ><v-color-picker
                                  show-swatches
                                  :swatches="swatches"
                                  class="map_icon_color_picker"
                                  v-model="selected_color"
                                >
                                </v-color-picker>

                                <v-btn
                                  class="mb-3 ml-3 mt-n2"
                                  depressed
                                  @click="remove_map_marker(i, 'wifi')"
                                  ><v-icon color="primary"
                                    >mdi-map-marker-remove</v-icon
                                  ></v-btn
                                >
                              </v-card>
                            </v-menu>
                            <v-icon
                              color="gray"
                              class="non_placed_hardware"
                              v-if="
                                !(
                                  !this.wifis[i].coordinates_lat ||
                                  !this.wifis[i].coordinates_long
                                )
                              "
                              >mdi-wifi</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content
                            :draggable="
                              !this.wifis[i].coordinates_lat ||
                              !this.wifis[i].coordinates_long
                            "
                            :class="
                              'map_item_content_' +
                              String(
                                !this.wifis[i].coordinates_lat ||
                                  !this.wifis[i].coordinates_long
                              )
                            "
                            @dragend="select_hardware(i, 'wifi')"
                          >
                            <v-list-item-title
                              v-if="
                                !(
                                  !this.wifis[i].coordinates_lat ||
                                  !this.wifis[i].coordinates_long
                                )
                              "
                              @mouseenter="set_marker('wifi ' + (i + 1))"
                              @mouseleave="set_marker(false)"
                              class="map_sidebar_item_name"
                              >{{ "wifi " + (i + 1) }}</v-list-item-title
                            >
                            <v-list-item-title
                              v-else
                              class="map_sidebar_item_name"
                              >{{ "wifi " + (i + 1) }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                    <v-col class="pt-0 pb-0 pl-0" v-if="site_map_info">
                      <GmapMap
                        class="settings_map"
                        :center="{
                          lat: parseFloat(site_map_info.location.latitude),
                          lng: parseFloat(site_map_info.location.longitude),
                        }"
                        :zoom="site_map_info.zoom"
                        map-type-id="hybrid"
                        style="height: calc(75vh + 8px)"
                        @click="edit_zone($event, create_zone)"
                        @mouseover="mark($event)"
                        @mouseout="mark('unmark')"
                        @zoom_changed="get_zoom"
                        @center_changed="get_center"
                        :options="{
                          draggableCursor: create_zone ? 'crosshair' : null,
                          mapTypeControl: true,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                        }"
                        ref="map"
                      >
                        <GmapPolygon
                          :options="{
                            fillColor: zone_colors[z.zone.color],
                            strokeColor: zone_colors[z.zone.color],
                          }"
                          :key="index"
                          v-for="(z, index) in selected_zones"
                          :paths="selected_zone_maps[index]"
                          :editable="zone_to_edit_index === index"
                          ref="polygon"
                          :draggable="zone_to_edit_index === index"
                          @click="update_polygon(z, index)"
                          @paths_changed="update_temp_zone_paths($event)"
                        >
                        </GmapPolygon>
                        <GmapInfoWindow
                          v-if="
                            zone_to_edit.id &&
                            selected_zone_maps[zone_to_edit_index]
                          "
                          :position="zone_confirm_location"
                        >
                          <v-icon color="oc_green" @click="save_zone_paths"
                            >mdi-checkbox-marked-circle</v-icon
                          >
                          <v-icon
                            color="oc_red"
                            @click="cancel_update_zone_paths"
                            >mdi-close-circle</v-icon
                          >
                        </GmapInfoWindow>
                        <div
                          v-if="
                            (zone_names || zone_area || zone_perimeter) &&
                            selected_zone_maps.length
                          "
                        >
                          <div>
                            <GmapInfoWindow
                              :key="index"
                              v-for="(z, index) in selected_zones"
                              :position="
                                selected_zone_maps[index]
                                  ? selected_zone_maps[index][1]
                                  : {}
                              "
                              :opened="true"
                              ><span
                                v-if="zone_names"
                                style="font-size: 16px"
                                >{{ z.zone.zone_name }}</span
                              ><span v-if="zone_names && zone_area"> - </span>
                              <span v-if="zone_area" style="font-size: 16px"
                                >{{
                                  "A: " +
                                  String(z.zone.zone_area_square_meters).split(
                                    "."
                                  )[0] +
                                  " m&sup2;"
                                }} </span
                              ><span
                                v-if="
                                  (zone_names || zone_area) && zone_perimeter
                                "
                                >,
                              </span>
                              <span
                                v-if="zone_perimeter"
                                style="font-size: 16px"
                                >{{
                                  "P: " +
                                  String(z.zone.zone_perimeter_meters).split(
                                    "."
                                  )[0] +
                                  " m"
                                }}
                              </span></GmapInfoWindow
                            >
                          </div>
                        </div>
                        <GmapMarker
                          :key="index + m[0].position"
                          v-for="(m, index) in hardware_markers"
                          :position="m[0].position"
                          :icon="m[0].icon"
                          :draggable="map_distances.length == 0"
                          @dragend="
                            update_camera_location(m[3], $event.latLng, m[2])
                          "
                          @click="get_distances(m[0], m[2])"
                          @mouseover="set_marker(m[0].title)"
                          @mouseout="set_marker(false)"
                          ><GmapInfoWindow
                            :opened="
                              open_marker == m[0].title &&
                              map_distances.length == 0
                            "
                            ><h3>{{ m[0].title }}</h3>
                            <span v-if="m[2] == 'camera'">{{
                              m[0].model
                            }}</span> </GmapInfoWindow
                          ><GmapInfoWindow
                            :opened="
                              m[2] == 'wifi' &&
                              map_distances.length > 0 &&
                              open_wifi == m[0].title
                            "
                          >
                            <span class="distance_list_close"
                              ><h3>{{ m[0].title }}</h3>
                              <v-icon @click="map_distances = []"
                                >mdi-close</v-icon
                              ></span
                            >
                            <v-list
                              v-if="map_distances"
                              width="280"
                              max-height="300"
                            >
                              <v-list-item
                                v-for="(camera, i) in map_distances"
                                :key="i"
                                class="pa-1 pb-0 pt-0 distance_list_item"
                              >
                                <v-list-item-icon class="mt-0 pt-1 mb-0 mr-4">
                                  <v-icon :color="get_color(camera[0].color)"
                                    >mdi-cctv</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content
                                  class="pt-0 pb-4 distance_list_content"
                                >
                                  <v-list-item-title
                                    class="map_sidebar_item_name"
                                  >
                                    <span class="distance_list_title">{{
                                      camera[0].title
                                    }}</span>
                                    <span class="nav_arrows_f">
                                      {{ camera[1] + " m" }}</span
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </GmapInfoWindow>
                        </GmapMarker>
                      </GmapMap></v-col
                    >
                  </v-row></v-card-text
                ></v-card
              >
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
      <v-dialog v-model="zone_dialog" max-width="800px" min-width="760px">
        <v-card class="pb-3">
          <v-card-text>
            <v-row>
              <v-toolbar elevation="0"
                ><h2 style="color: #0090a4" v-if="!editing_zone">Add Zone</h2>
                <h2 style="color: #0090a4" v-else>Edit Zone</h2>
                <v-spacer></v-spacer>
                <v-btn icon @click="zone_dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn></v-toolbar
              ></v-row
            >

            <v-row class="mb-0">
              <v-col cols="5">
                <v-text-field
                  v-model="created_zone_object.name"
                  label="Name*"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-select
                  v-model="created_zone_object.type"
                  label="Type*"
                  dense
                  outlined
                  :items="zone_types"
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="2">
                <v-select
                  v-model="created_zone_object.color"
                  label="Color*"
                  dense
                  outlined
                  :items="[
                    { text: '#007AFF', value: 'BLUE' },
                    { text: '#00C7BE', value: 'MINT' },
                    { text: '#34C759', value: 'GREEN' },
                    { text: '#5856D6', value: 'INDIGO' },
                    { text: '#AF52DE', value: 'PURPLE' },
                    { text: '#FF2D54', value: 'PINK' },
                    { text: '#FF3A30', value: 'RED' },
                    { text: '#FF9500', value: 'ORANGE' },
                    { text: '#FFCC00', value: 'YELLOW' },
                  ]"
                  hide-details
                  ><template v-slot:item="{ item }">
                    <v-icon :color="item.text" large>mdi-rectangle</v-icon>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-icon :color="item.text">mdi-rectangle</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="pr-6">
            <v-icon v-if="editing_zone" class="ml-1" @click="delete_zone"
              >mdi-delete</v-icon
            >

            <v-spacer></v-spacer>
            <v-btn
              @click="
                zone_edited = false;
                zone_dialog = false;
                created_zone_object = {};
              "
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="
                !created_zone_object.name ||
                !created_zone_object.type ||
                !created_zone_object.color
              "
              v-if="!zone_edited"
              @click="add_zone"
            >
              Save
            </v-btn>
            <v-btn color="primary" v-else @click="update_zone"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-snackbar
      v-model="create_zone"
      :timeout="-1"
      color="primary"
      width="270"
      min-width="100"
    >
      Click on the map to place the Zone
    </v-snackbar>
    <!-- <events></events> -->
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import { eventBus } from "../main.js";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";

//var AWSCognito = require("amazon-cognito-identity-js");

export default {
  name: "UserSettings",
  components: {
    navbar,
    sidenav,
    //events,
  },
  props: ["setting"],
  data() {
    return {
      map_distances: [],
      open_marker: false,
      color_device: null,
      color_device_type: null,
      zone_colors: {
        BLUE: "#007AFF",
        MINT: "#00C7BE",
        GREEN: "#34C759",
        INDIGO: "#5856D6",
        PURPLE: "#AF52DE",
        PINK: "#FF2D54",
        RED: "#FF3A30",
        ORANGE: "#FF9500",
        YELLOW: "#FFCC00",
        null: "#0090a4",
      },
      swatches: [
        ["#007AFF", "#5856D6", "#FF3A30"],
        ["#00C7BE", "#AF52DE", "#FF9500"],
        ["#34C759", "#FF2D54", "#FFCC00"],
      ],
      selected_color: "#007AFF",
      mark_lat: null,
      mark_long: null,
      selected_site: {},
      dialog: false,
      site_operation_type_comp: null,
      site_community_pasture_comp: null,
      site_community_pasture_names_comp: null,
      markers_comp: null,
      open_wifi: false,

      create_zone: false,
      zone_dialog: false,
      created_zone_object: {},
      selected_zone_maps: [],
      mvc_paths: [],
      zone_dragging: false,
      zone_selection: [],
      selected_zones: [],
      zone_to_edit: {},
      zone_edited: false,
      zone_to_edit_index: false,
      zone_names: false,
      zone_area: false,
      zone_perimeter: false,
      editing_zone: false,

      timeout: false,
      updated_zone_paths: false,
      inial_temp_zone: [],


      default_coords: false,

      zones: [],
      cameras: [],
      wifis: [],
    };
  },
  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    zone_confirm_location() {

      return this.selected_zone_maps[this.zone_to_edit_index][0];
    },
    zone_types() {
      return this.$store.getters.getEnums.zone_type
        ? this.$store.getters.getEnums.zone_type
        : [];
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    google: () => window.google,
    zone_path() {
      if (!this.mvc_paths || this.mvc_paths.length == 0) {
        var temp_zone_map = [];
        for (const i in this.selected_zone_maps) {
          temp_zone_map.push({
            latitude: this.selected_zone_maps[i].lat,
            longitude: this.selected_zone_maps[i].lng,
          });
        }
        return temp_zone_map;
      }

      let path = [];
      for (let j = 0; j < this.mvc_paths.getAt(0).getLength(); j++) {
        let point = this.mvc_paths.getAt(0).getAt(j);
        path.push({ latitude: point.lat(), longitude: point.lng() });
      }

      return path;
    },

    sites() {
      var sites = JSON.parse(JSON.stringify(this.$store.getters.getSites));
      var temp_sites_object = [];
      for (const s in sites) {
        temp_sites_object.push({ value: sites[s], text: sites[s].name });
      }
      return temp_sites_object;
    },
    site_map_info() {
      var zoom = 17;
      if (this.$store.getters.getSites && this.selected_site) {
        if (this.selected_site.site_map_zoom_level) {
          zoom = this.selected_site.site_map_zoom_level;
        }
        return {
          location:
            this.selected_site.coordinates_long &&
            this.selected_site.coordinates_lat
              ? {
                  latitude: this.selected_site.coordinates_lat,
                  longitude: this.selected_site.coordinates_long,
                }
              : { latitude: 0, longitude: 0 },
          zoom: zoom,
        };
      } else {
        return false;
      }
    },

    hardware_markers: {
      get: function () {
        if (this.markers_comp != null) {
          return this.markers_comp;
        }
        var markers = [];
        if (this.$store.getters.getSites) {
          for (const i in this.cameras) {
            if (
              this.cameras[i].coordinates_lat &&
              this.cameras[i].coordinates_long
            ) {
              var camera_icon = "ORANGE";
              if (
                this.cameras[i].map_icon_color &&
                this.cameras[i].map_icon_color != "UNDEFINED"
              ) {
                camera_icon = this.cameras[i].map_icon_color;
              }
              markers.push([
                {
                  position: {
                    lat: parseFloat(this.cameras[i].coordinates_lat),
                    lng: parseFloat(this.cameras[i].coordinates_long),
                  },
                  icon:
                    "https://map-icons-onecup.s3.us-west-2.amazonaws.com/camera_" +
                    camera_icon.toLowerCase() +
                    ".png",
                  title: this.cameras[i].name,
                  thumbnail:
                    process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
                    this.cameras[i].customer_id +
                    "/thumbnails/" +
                    this.cameras[i].id +
                    "_latest.jpg",
                  color: this.cameras[i].map_icon_color,
                },
                {
                  id: this.cameras[i].id,
                },
                "camera",
                i,
              ]);
            }
          }
          for (const i in this.wifis) {
            if (
              this.wifis[i].coordinates_lat &&
              this.wifis[i].coordinates_long
            ) {
              var wifi_icon = "ORANGE";
              if (
                this.wifis[i].map_icon_color &&
                this.wifis[i].map_icon_color != "UNDEFINED"
              ) {
                wifi_icon = this.wifis[i].map_icon_color;
              }
              markers.push([
                {
                  position: {
                    lat: parseFloat(this.wifis[i].coordinates_lat),
                    lng: parseFloat(this.wifis[i].coordinates_long),
                  },
                  icon:
                    "https://map-icons-onecup.s3.us-west-2.amazonaws.com/wifi_" +
                    wifi_icon.toLowerCase() +
                    ".png",
                  title: "wifi " + String(parseInt(i) + parseInt(1)),
                },
                {
                  id: this.wifis[i].id,
                },
                "wifi",
                i,
              ]);
            }
          }

          // var site_map_zoom_level = 18;
          // if (this.$store.getters.getSite[0].site_map_zoom_level) {
          //   site_map_zoom_level =
          //     this.$store.getters.getSite[0].site_map_zoom_level;
          // }
          markers = this.sortByNested([...markers], "0.position.lng");

          return markers;
        } else {
          return false;
        }
      },
      set: function () {
        if (this.markers_comp == null) {
          this.markers_comp = this.hardware_markers;
        }
      },
    },

    customer() {
      return this.$store.getters.getUserDDB;
    },
  },
  methods: {
    async update_default_site_location() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_site_by_pk,
            variables: {
              pk_columns: {
                id: this.selected_site.id,
              },
              _set: this.default_coords,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.default_coords = false;
        // this.$store.dispatch("DDB_GET_SITES");
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated site location`,
          err: false,
        });
      } catch {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating site location`,
          err: true,
        });
      }
    },
    get_center(center) {
      if (!this.default_coords) {
        this.default_coords = {};
      }
      this.default_coords.coordinates_long = center.lng();
      this.default_coords.coordinates_lat = center.lat();
    },

    get_zoom(zoom) {
      if (!this.default_coords) {
        this.default_coords = {};
      }
      this.default_coords.map_zoom_level = zoom;
    },
    async delete_zone() {
      try {
      let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_zone_by_pk,
            variables: {
              pk_columns: {
                id: this.zones[this.zone_to_edit_index].id,
              },
              _set: { deleted_at: moment().format("YYYY-MM-DDTHH:mm:ss") },
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
      const z_index = this.zone_to_edit_index;
      this.zones.splice(z_index, 1);
      this.zone_selection.splice(this.zone_selection.indexOf(z_index), 1);
      // this.$store.dispatch("DDB_GET_ZONES");
      this.zone_edited = false;
      this.zone_dialog = false;
      this.created_zone_object = {};
      this.zone_to_edit_index = false;
      // const _this = this;
      // setTimeout(function () {
      //   _this.zone_selection.push(z_index);
      // }, 200);
      eventBus.$emit("add_button_alert", {
          msg: `Successfully deleted zone`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error deleting the zone`,
          err: true,
        });
      }
    },
    select_all_zones() {
      if (this.zone_selection.length == this.zones.length - 1) {
        this.zone_selection = [];
      } else {
        this.zone_selection = [...Array(this.zones.length - 1).keys()];
      }
    },
    async update_zone() {
      try {
        let response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_zone_by_pk,
            variables: {
              pk_columns: {
                id: this.zone_edited.id,
              },
              _set: this.created_zone_object,
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );

        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        this.zones[this.zone_to_edit_index].name =
          this.created_zone_object.name;
        this.zones[this.zone_to_edit_index].type =
          this.created_zone_object.type;
        this.zones[this.zone_to_edit_index].color =
          this.created_zone_object.color;

        this.zone_edited = false;
        this.zone_dialog = false;
        this.created_zone_object = {};
        this.zone_to_edit_index = false;

        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated zone`,
          err: false,
        });
      } catch {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating zone`,
          err: true,
        });
      }
    },
    select_zone_to_edit(index) {
      this.zone_edited = this.zones[index];
      this.zone_to_edit_index = index;
      this.editing_zone = true;
      this.zone_dialog = true;
      this.created_zone_object = {
        name: this.zones[index].name,
        type: this.zones[index].type,
        color: this.zones[index].color,
      };
    },
    async add_zone() {
      this.create_zone = true;
      this.zone_dialog = false;
    },
    debounce(fn, delay) {
      var timeoutID = null;
      return function () {
        clearTimeout(timeoutID);
        var args = arguments;
        var that = this;
        timeoutID = setTimeout(function () {
          fn.apply(that, args);
        }, delay);
      };
    },

    update_temp_zone_paths(mvc_paths) {
      this.updated_zone_paths = mvc_paths;
    },
    update_polygon(z, i) {
      if (this.inial_temp_zone.length > 1) {
        this.cancel_update_zone_paths;
        const _this = this;
        setTimeout(function () {
          _this.zone_to_edit_index = i;
          _this.zone_to_edit = z.zone;
          _this.inial_temp_zone = _this.selected_zone_maps[i];
        }, 500);
      } else {
        this.zone_to_edit_index = i;
        this.zone_to_edit = z.zone;
        this.inial_temp_zone = this.selected_zone_maps[i];
      }
    },
    save_zone_paths() {
      this.mvc_paths = this.updated_zone_paths;
    },
    cancel_update_zone_paths() {
      this.selected_zone_maps[this.zone_to_edit_index] = this.inial_temp_zone;
      const temp_sz = [...this.selected_zone_maps];
      this.zone_to_edit_index = false;
      this.zone_to_edit = {};
      this.inial_temp_zone = [];
      this.selected_zone_maps = [];
      const _this = this;
      setTimeout(function () {
        _this.selected_zone_maps = temp_sz;
      }, 400);
    },
    async edit_zone(event, create_zone) {
      if (create_zone) {
        try {
          this.zone_to_edit_index = false;
          this.zone_to_edit = {};
          this.inial_temp_zone = [];
          var lat = event.latLng.lat();
          var lng = event.latLng.lng();

          var temp_zone_lat = [
            lat + 0.0003,
            lat + 0.0003,
            lat - 0.0003,
            lat - 0.0003,
          ];
          var temp_zone_long = [
            lng - 0.0006,
            lng + 0.0006,
            lng + 0.0006,
            lng - 0.0006,
          ];

          var temp_paths = [
            { lat: lat + 0.0003, lng: lng - 0.0006 },
            { lat: lat + 0.0003, lng: lng + 0.0006 },
            { lat: lat - 0.0003, lng: lng + 0.0006 },
            { lat: lat - 0.0003, lng: lng - 0.0006 },
          ];
          var zone_area = 0;
          var zone_perimeter = 0;
          if (this.google.maps.geometry) {
            zone_area =
              this.google.maps.geometry.spherical.computeArea(temp_paths);
            zone_perimeter =
              this.google.maps.geometry.spherical.computeLength(temp_paths);
          }

          let id = uuidv4();

          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_zone_one,
              variables: {
                object1: {
                  id: id,
                  customer_id: this.$store.getters.getCustomerID,
                  site_id: this.selected_site.id,
                  name: this.created_zone_object.name,
                  type: this.created_zone_object.type,
                  color: this.created_zone_object.color,
                  perimeter_long: temp_zone_long,
                  perimeter_lat: temp_zone_lat,
                  area_square_meters: zone_area,
                  perimeter_meters: zone_perimeter,
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }

          this.$store.dispatch("DDB_GET_ZONES").then(() => {
            var temp_zones = [...this.$store.getters.getZones];
            temp_zones.push({ name: "+" });

            this.zones = temp_zones;
            var _this = this;
            for (const i in _this.zones) {
              if (_this.zones[i].id == id) {
                _this.zone_selection.push(parseInt(i));
              }
            }
          });

          eventBus.$emit("add_button_alert", {
            msg: `Successfully created zone`,
            err: false,
          });
        } catch {
          eventBus.$emit("add_button_alert", {
            msg: `Error creating zone`,
            err: true,
          });
        }

        this.create_zone = false;
        this.created_zone_object = {};
      }
    },
    zoomout() {
      try {
        var bounds = this.$refs.map.$mapObject.getBounds();
        for (const i in this.hardware_markers) {
          bounds.extend(this.hardware_markers[i][0].position);
        }
        this.$refs.map.fitBounds(bounds);
      } catch (error) {
        return error;
      }
    },
    get_single_distance(w, c) {
      var p = 0.017453292519943295; // Math.PI / 180
      var mcos = Math.cos;
      var a =
        0.5 -
        mcos((c.lat - w.lat) * p) / 2 +
        (mcos(w.lat * p) * mcos(c.lat * p) * (1 - mcos((c.lat - w.lat) * p))) /
          2;

      return parseInt(12742000 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    },
    get_distances(obj, dt) {
      if (dt == "wifi") {
        var distance_array = [];
        for (const i in this.hardware_markers) {
          if (this.hardware_markers[i][2] == "camera") {
            distance_array.push([
              this.hardware_markers[i][0],
              this.get_single_distance(
                obj.position,
                this.hardware_markers[i][0].position
              ),
            ]);
          }
        }
        distance_array.sort(
          (function (index) {
            return function (b, a) {
              return a[index] === b[index] ? 0 : a[index] < b[index] ? -1 : 1;
            };
          })(1)
        );
        this.open_wifi = obj.title;
        this.map_distances = distance_array;
      }
    },
    set_marker(marker) {
      if (marker) {
        this.open_marker = marker;
      } else {
        this.open_marker = false;
      }
    },
    async remove_map_marker(index, device_type) {
      try {
        if (device_type == "camera") {
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_camera_by_pk,
              variables: {
                pk_columns: { id: this.cameras[index].id },
                _set: {
                  coordinates_long: null,
                  coordinates_lat: null,
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.cameras[index].coordinates_long = null;
          this.cameras[index].coordinates_lat = null;
        } else if (device_type == "wifi") {
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_wifi_by_pk,
              variables: {
                pk_columns: { id: this.wifis[index].id },
                _set: {
                  coordinates_long: null,
                  coordinates_lat: null,
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.wifis[index].coordinates_long = null;
          this.wifis[index].coordinates_lat = null;
        }
        eventBus.$emit("add_button_alert", {
          msg: `Successfully removed ${device_type} marker`,
          err: false,
        });
      } catch {
        eventBus.$emit("add_button_alert", {
          msg: `Error removing ${device_type} marker`,
          err: true,
        });
      }
    },
    select_color(device, device_type) {
      this.color_device = device;
      this.color_device_type = device_type;
      let col_object = {
        BLUE: "#007AFF",
        MINT: "#00C7BE",
        GREEN: "#34C759",
        INDIGO: "#5856D6",
        PURPLE: "#AF52DE",
        PINK: "#FF2D54",
        RED: "#FF3A30",
        ORANGE: "#FF9500",
        YELLOW: "#FFCC00",
      };
      var temp_selected_color = "";
      if (device_type == "camera") {
        if (device.map_icon_color) {
          temp_selected_color = col_object[device.map_icon_color];
        } else {
          temp_selected_color = col_object["ORANGE"];
        }
      } else if (device_type == "wifi") {
        if (device.map_icon_color) {
          temp_selected_color = col_object[device.map_icon_color];
        } else {
          temp_selected_color = col_object["ORANGE"];
        }
      }

      if (typeof temp_selected_color !== "object") {
        this.selected_color = temp_selected_color;
      } else {
        this.selected_color = temp_selected_color.hex;
      }
    },
    select_hardware(index, device_type) {
      let _this = this;
      setTimeout(function () {
        _this.update_camera_location(index, null, device_type);
      }, 100);
    },
    async mark(event) {
      if (event == "unmark") {
        this.mark_lat = null;
        this.mark_long = null;
      } else {
        this.mark_lat = event.latLng.lat();
        this.mark_long = event.latLng.lng();
      }
    },
    async update_camera_location(index, location, device) {
      try {
        if (device == "camera") {
          var cam_object = {};
          if (location != null) {
            cam_object = {
              coordinates_long: location.lng(),
              coordinates_lat: location.lat(),
            };
          } else {
            if (this.mark_lat != null && this.mark_long != null) {
              cam_object = {
                coordinates_long: this.mark_long,
                coordinates_lat: this.mark_lat,
              };
              this.mark_lat = null;
              this.mark_long = null;
            }
          }
          if (this.cameras[index].id) {
            let response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_camera_by_pk,
                variables: {
                  pk_columns: { id: this.cameras[index].id },
                  _set: cam_object,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
              throw new Error("GraphQL error: " + response.errors[0].message);
            }
            this.cameras[index].coordinates_long = cam_object.coordinates_long;
            this.cameras[index].coordinates_lat = cam_object.coordinates_lat;
          }
        } else if (device == "wifi") {
          var wifi_object = {};
          if (location != null) {
            wifi_object = {
              coordinates_long: location.lng(),
              coordinates_lat: location.lat(),
            };
          } else {
            if (this.mark_lat != null && this.mark_long != null) {
              wifi_object = {
                coordinates_long: this.mark_long,
                coordinates_lat: this.mark_lat,
              };
              this.mark_lat = null;
              this.mark_long = null;
            }
          }
          if (this.wifis[index].id) {
            let response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_wifi_by_pk,
                variables: {
                  pk_columns: { id: this.wifis[index].id },
                  _set: wifi_object,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
              throw new Error("GraphQL error: " + response.errors[0].message);
            }
            this.wifis[index].coordinates_long = wifi_object.coordinates_long;
            this.wifis[index].coordinates_lat = wifi_object.coordinates_lat;
          }
        }
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated ${device} location`,
          err: false,
        });
      } catch {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating ${device} location`,
          err: true,
        });
      }
    },
    get_color(color) {
      if (color) {
        if (color == "MINT") {
          return "#00C7BE";
        }
        return color.toLowerCase();
      }
      return "ORANGE".toLowerCase();
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });

      return array;
    },
  },
  watch: {
    customer() {
      if (
        this.$store.getters.getUserDDB &&
        this.$store.getters.getUserDDB.type == "USER"
      ) {
        this.$router.push({ path: "/" });
      }
    },


    zone_dialog() {
      if (!this.zone_dialog) {
        this.editing_zone = false;
      }
    },
    sites() {
      if (!this.selected_site.site_name && this.sites[0]) {
        this.selected_site = this.sites[0].value;
      }
    },
    zone_selection() {
      this.zone_to_edit_index = false;
      this.zone_to_edit = {};
      this.inial_temp_zone = [];
      if (this.zone_selection.length) {
        var temp_maps = [];
        var temp_zones = [];
        for (const i in this.zone_selection) {
          temp_zones.push({
            zone: this.zones[this.zone_selection[i]],
            index: this.zone_selection[i],
          });
          this.selected_zones = temp_zones;
          var temp_zone_map = [];
          this.selected_zone_maps.push([]);
          if (
            this.selected_zones[i].zone.perimeter_long &&
            this.selected_zones[i].zone.perimeter_long.length
          ) {
            for (const n in this.selected_zones[i].zone.perimeter_long) {
              temp_zone_map.push({
                lat: parseFloat(this.selected_zones[i].zone.perimeter_lat[n]),
                lng: parseFloat(this.selected_zones[i].zone.perimeter_long[n]),
              });
            }
          }

          temp_maps.push(temp_zone_map);
        }

        this.selected_zone_maps = temp_maps;
      } else {
        this.selected_zones = [];
        this.selected_zone_maps = [];
      }
    },
    async zone_path() {
      if (this.zone_to_edit.id) {
        try {
          var temp_paths = [];
          var temp_perimeter_long = [];
          var temp_perimeter_lat = [];

          for (const i in this.zone_path) {
            temp_perimeter_long.push(this.zone_path[i].longitude);
            temp_perimeter_lat.push(this.zone_path[i].latitude);
            temp_paths.push({
              lat: this.zone_path[i].latitude,
              lng: this.zone_path[i].longitude,
            });
          }
          const zone_area =
            this.google.maps.geometry.spherical.computeArea(temp_paths);

          const zone_perimeter =
            this.google.maps.geometry.spherical.computeLength(temp_paths);
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_zone_by_pk,
              variables: {
                pk_columns: {
                  id: this.zones[this.zone_to_edit_index].id,
                },
                _set: {
                  area_square_meters: zone_area,
                  perimeter_meters: zone_perimeter,
                  perimeter_long: temp_perimeter_long,
                  perimeter_lat: temp_perimeter_lat,
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.zones[this.zone_to_edit_index].perimeter_long =
            temp_perimeter_long;
          this.zones[this.zone_to_edit_index].perimeter_lat =
            temp_perimeter_lat;

          this.zone_to_edit = false;
          this.zone_to_edit_index = false;

          eventBus.$emit("add_button_alert", {
            msg: `Successfully updated zone`,
            err: false,
          });
        } catch {
          eventBus.$emit("add_button_alert", {
            msg: `Error updating zone`,
            err: true,
          });
        }
      }
    },

    async selected_color() {
      let col_object = {
        "#007AFF": "BLUE",
        "#00C7BE": "MINT",
        "#34C759": "GREEN",
        "#5856D6": "INDIGO",
        "#AF52DE": "PURPLE",
        "#FF2D54": "PINK",
        "#FF3A30": "RED",
        "#FF9500": "ORANGE",
        "#FFCC00": "YELLOW",
      };

      try {
        if (
          this.cameras[this.color_device].map_icon_color ==
            col_object[this.selected_color] ||
          this.selected_color == undefined
        ) {
          return false;
        }
        if (this.color_device_type == "camera") {
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_camera_by_pk,
              variables: {
                pk_columns: { id: this.cameras[this.color_device].id },
                _set: {
                  map_icon_color:
                    col_object[
                      typeof this.selected_color !== "object"
                        ? this.selected_color
                        : this.selected_color.hex
                    ],
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );

          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.cameras[this.color_device].map_icon_color =
            col_object[
              typeof this.selected_color !== "object"
                ? this.selected_color
                : this.selected_color.hex
            ];
        } else if (this.color_device_type == "wifi") {
          if (
            this.wifis[this.color_device].map_icon_color ==
              col_object[this.selected_color] ||
            this.selected_color == undefined
          ) {
            return false;
          }
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_wifi_by_pk,
              variables: {
                pk_columns: { id: this.wifis[this.color_device].id },
                _set: {
                  map_icon_color:
                    col_object[
                      typeof this.selected_color !== "object"
                        ? this.selected_color
                        : this.selected_color.hex
                    ],
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          this.wifis[this.color_device].map_icon_color =
            col_object[
              typeof this.selected_color !== "object"
                ? this.selected_color
                : this.selected_color.hex
            ];
        }
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated ${this.color_device_type} color`,
          err: false,
        });
      } catch {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating ${this.color_device_type} color`,
          err: true,
        });
      }
    },

    customer_id(n, o) {
      if (o || n) {
        this.$store.dispatch("DDB_GET_ZONES").then(() => {
          var temp_zones = [...this.$store.getters.getZones];
          temp_zones.push({ name: "+" });

          this.zones = temp_zones;
          this.select_all_zones()
        });

        if (!this.$store.getters.getCameraObjects.length) {
          this.$store.dispatch("DDB_GET_CAMERAS").then(() => {
            this.cameras = this.$store.getters.getCameraObjects;
          });
        } else {
          this.cameras = this.$store.getters.getCameraObjects;
        }

        if (!this.$store.getters.getWifis.length) {
          this.$store.dispatch("DDB_GET_WIFIS").then(() => {
            this.wifis = this.$store.getters.getWifis;
          });
        } else {
          this.wifis = this.$store.getters.getWifis;
        }
        if (
          this.$store.getters.getUserDDB &&
          this.$store.getters.getUserDDB.type == "USER"
        ) {
          this.$router.push({ path: "/" });
        }
        if (this.sites[0]) {
          this.selected_site = this.sites[0].value;
        }
      }
        
      
    },
  },
  mounted() {
    if (this.customer_id) {
      this.$store.dispatch("DDB_GET_ZONES").then(() => {
          var temp_zones = [...this.$store.getters.getZones];
          temp_zones.push({ name: "+" });

          this.zones = temp_zones;
          this.select_all_zones()
        });

        if (!this.$store.getters.getCameraObjects.length) {
          this.$store.dispatch("DDB_GET_CAMERAS").then(() => {
            this.cameras = this.$store.getters.getCameraObjects;
          });
        } else {
          this.cameras = this.$store.getters.getCameraObjects;
        }

        if (!this.$store.getters.getWifis.length) {
          this.$store.dispatch("DDB_GET_WIFIS").then(() => {
            this.wifis = this.$store.getters.getWifis;
          });
        } else {
          this.wifis = this.$store.getters.getWifis;
        }
        if (
          this.$store.getters.getUserDDB &&
          this.$store.getters.getUserDDB.type == "USER"
        ) {
          this.$router.push({ path: "/" });
        }
        if (this.sites[0]) {
          this.selected_site = this.sites[0].value;
        }
    }
    //setTimeout(this.zoomout, 2000);
  },
};
</script>

<style lang="css">
.selected_chip_null {
  color: #0090a4 !important;
  caret-color: #0090a4 !important;
  background-color: #d9f3f7 !important;
}
.selected_chip_BLUE {
  color: #007aff !important;
  caret-color: #007aff !important;
  background-color: #d9daf7 !important;
}
.selected_chip_MINT {
  color: #00c7be !important;
  caret-color: #00c7be !important;
  background-color: #d9f7f3 !important;
}
.selected_chip_GREEN {
  color: #34c759 !important;
  caret-color: #34c759 !important;
  background-color: #d9f7e3 !important;
}
.selected_chip_INDIGO {
  color: #5856d6 !important;
  caret-color: #5856d6 !important;
  background-color: #dfd9f7 !important;
}
.selected_chip_PURPLE {
  color: #af52de !important;
  caret-color: #af52de !important;
  background-color: #f1d9f7 !important;
}
.selected_chip_PINK {
  color: #ff2d54 !important;
  caret-color: #ff2d54 !important;
  background-color: #f7d9e9 !important;
}
.selected_chip_RED {
  color: #ff3a30 !important;
  caret-color: #ff3a30 !important;
  background-color: #f7d9d9 !important;
}
.selected_chip_ORANGE {
  color: #ff9500 !important;
  caret-color: #ff9500 !important;
  background-color: #f7edd9 !important;
}
.selected_chip_YELLOW {
  color: #ffcc00 !important;
  caret-color: #ffcc00 !important;
  background-color: #f3f7d9 !important;
}

.non_placed_hardware {
  cursor: initial;
}

.map_item_content_false {
  cursor: pointer !important;
}
.map_item_content_true {
  color: gray;
}
.settings_map {
  pointer-events: inherit !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.map_side_bar .v-list-item {
  cursor: move;
}

.map_side_bar {
  overflow-y: scroll;
}
.settings_card_title {
  background-color: #ff9900;
}
.text_field_col {
  text-align: left;
}
.text_field_title {
  font-weight: bold;
}
.setting_tab {
  font-weight: bold;
  font-size: 14px;
}
.val_error {
  color: red;
}
</style>
